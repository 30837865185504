import { BaseService, noArgsRequestWrapper } from "@utils"
import { AxiosResponse } from "axios"

import { IIndustrialObject } from "@types"

class IndustrialObjectService extends BaseService {
  getIndustrialObjectList = (): Promise<AxiosResponse<IIndustrialObject[]>> => {
    return this.api.get("/v1/building/")
  }
}

const industrialObject = new IndustrialObjectService()

export const industrialObjectAPI = {
  getIndustrialObjectList: noArgsRequestWrapper<IIndustrialObject[], typeof industrialObject.getIndustrialObjectList>(
    industrialObject.getIndustrialObjectList
  ),
}
