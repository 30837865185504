import React from "react"

interface IBCLogo {
  width?: number
  height?: number
}

/**
 * Логотип
 *
 * @param {number} [width=256] - Ширина
 * @param {number} [height=58] - Высота
 *
 * @returns {React.FC<IBCLogo>} - React.FC<IBCLogo>
 */
export const BCLogo: React.FC<IBCLogo> = ({ width = 142, height = 30 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 637 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_70_182)">
        <path
          d="M198.354 61.122C198.354 55.9848 198.398 50.8512 198.328 45.714C198.306 44.1544 198.753 43.538 200.415 43.571C205.461 43.6701 210.518 43.4242 215.553 43.6958C221.567 44.0223 226.879 46.0589 230.299 51.4016C235.741 59.9074 233.063 75.0292 219.096 77.862C217.341 78.218 215.568 78.3831 213.776 78.3831C209.406 78.3831 205.036 78.317 200.666 78.4161C198.93 78.4565 198.283 77.9721 198.324 76.1557C198.435 71.1432 198.361 66.1308 198.361 61.1146H198.35L198.354 61.122ZM206.514 60.9275H206.521C206.521 63.797 206.591 66.6702 206.492 69.5397C206.44 71.0332 207.038 71.4845 208.435 71.4368C210.4 71.3708 212.369 71.4478 214.338 71.4111C220.791 71.29 224.533 67.0922 224.489 60.0578C224.456 54.5683 220.37 50.7741 214.382 50.6934C212.354 50.664 210.322 50.7484 208.294 50.6677C206.902 50.6127 206.466 51.1888 206.492 52.5024C206.551 55.3096 206.51 58.1204 206.51 60.9312L206.514 60.9275Z"
          fill="white"
        />
        <path
          d="M305.764 79.1536C296.023 79.1352 289.13 74.009 287.231 65.3638C285.432 57.1809 288.602 49.4017 295.369 45.3763C303.097 40.7821 315.557 42.5765 320.714 49.2292C325.549 55.4709 325.945 62.4172 322.842 69.4625C320.093 75.7006 313.644 79.1609 305.764 79.1462V79.1536ZM316.333 61.1696C316.37 52.7959 310.574 48.6017 302.887 50.2933C295.303 51.9593 292.41 62.9676 297.811 68.8718C300.633 71.9541 305.665 73.0476 309.883 71.4954C313.991 69.9836 316.252 66.3619 316.337 61.1733L316.333 61.1696Z"
          fill="white"
        />
        <path
          d="M541.291 79.1977C533.833 78.9885 527.69 76.7685 524.121 70.0461C517.317 57.236 525.721 43.1343 540.257 42.9105C546.869 42.8078 552.816 44.5471 556.647 50.2971C560.91 56.693 561.095 63.5732 557.53 70.3176C554.091 76.8199 548.018 78.9372 541.287 79.1977H541.291ZM530.368 61.122C530.253 71.69 540.054 73.866 545.923 71.0845C550.005 69.1507 551.967 64.8135 551.409 59.2873C550.755 52.774 545.731 48.7926 538.358 50.209C532.968 51.2438 530.368 55.0564 530.364 61.122H530.368Z"
          fill="white"
        />
        <path
          d="M487.414 60.9128C487.414 55.8379 487.462 50.7631 487.384 45.6919C487.358 44.136 487.757 43.4829 489.445 43.5673C495.094 43.8461 500.779 43.1269 506.397 44.0737C510.797 44.8149 514.565 46.6459 516.098 51.2034C517.598 55.6654 516.851 62.5714 510.49 65.0482C509.452 65.4519 509.515 65.9142 510.239 66.6334C513.006 69.3782 515.104 72.6 516.888 76.0382C518 78.1849 517.882 78.1555 515.555 78.4307C511.425 78.9224 508.344 78.0344 506.789 73.7302C506.109 71.8477 504.347 70.5377 503.128 68.9305C501.95 67.3747 500.28 67.7489 498.725 67.7453C495.382 67.7379 495.64 67.2829 495.626 70.7396C495.618 72.6954 495.578 74.6512 495.622 76.607C495.648 77.8179 495.23 78.4344 493.926 78.3904C492.39 78.339 490.846 78.317 489.313 78.405C487.72 78.4968 487.362 77.7555 487.381 76.3171C487.447 71.1836 487.406 66.0463 487.406 60.9128H487.417H487.414ZM495.603 55.5921C495.603 57.0158 495.607 58.3588 495.603 59.6982C495.6 60.3623 495.692 60.9678 496.553 60.9495C499.368 60.8944 502.219 61.3091 504.975 60.4541C507.524 59.6615 508.75 57.8011 508.573 55.1811C508.414 52.8143 506.963 51.2328 504.392 50.7777C503.25 50.5759 502.072 50.4695 500.912 50.5025C499.216 50.5502 497.066 49.765 495.954 50.8878C494.916 51.9373 495.759 54.0399 495.6 55.5921H495.603Z"
          fill="white"
        />
        <path
          d="M331.667 60.9201C331.667 55.8489 331.738 50.7741 331.631 45.7065C331.594 43.9672 332.174 43.5122 333.854 43.5819C339.133 43.8094 344.434 43.2774 349.705 43.9122C352.365 44.2314 354.844 45.0534 357.049 46.6459C362.727 50.7484 362.372 61.9292 355.043 64.9198C353.743 65.4519 353.791 65.9289 354.696 66.8463C357.555 69.7598 359.668 73.1907 361.526 76.7941C362.058 77.8253 362.021 78.3977 360.658 78.3793C358.752 78.3537 356.842 78.3463 354.94 78.4014C353.791 78.4344 353.325 77.7152 352.897 76.8639C351.549 74.1668 349.768 71.7743 347.836 69.4589C346.75 68.1562 345.572 67.5104 343.876 67.7122C343.089 67.8076 342.273 67.7856 341.479 67.7159C340.164 67.6022 339.768 68.1709 339.813 69.4295C339.89 71.6863 339.746 73.954 339.857 76.2107C339.942 77.9243 339.236 78.5188 337.589 78.416C336.241 78.3316 334.878 78.3243 333.533 78.4087C332.081 78.5004 331.597 77.9317 331.616 76.5006C331.679 71.3083 331.642 66.1124 331.642 60.9201H331.671H331.667ZM339.835 55.7462H339.828C339.828 57.0892 339.839 58.4285 339.824 59.7716C339.816 60.5018 340.09 60.9605 340.877 60.9531C343.573 60.9238 346.288 61.2393 348.944 60.5385C351.549 59.8523 352.956 57.8965 352.812 55.2618C352.679 52.829 351.135 51.2254 348.523 50.7594C346.022 50.3154 343.499 50.5649 340.987 50.4658C340.075 50.4291 339.824 50.9282 339.831 51.7208C339.846 53.0638 339.835 54.4031 339.835 55.7462Z"
          fill="white"
        />
        <path
          d="M479.494 66.4063C479.494 68.4832 479.479 70.5637 479.501 72.6407C479.513 73.6314 479.198 74.4167 478.375 75.0111C471.12 80.2621 458.634 80.7428 451.479 74.7102C443.529 68.0061 442.572 49.6369 457.234 44.3749C463.104 42.2686 469.055 42.4998 475.024 44.0446C476.214 44.3529 476.576 44.9473 476.469 46.1399C476.132 49.813 474.714 51.0276 471.05 50.6056C468.242 50.2827 465.464 49.9084 462.609 50.4515C457.105 51.5046 453.824 55.7832 454.12 61.7791C454.397 67.4264 458.006 71.1619 463.58 71.5949C466.14 71.7967 468.615 71.4591 470.998 70.516C472.235 70.028 472.671 68.4098 472.246 66.3879C471.969 65.0742 470.942 65.599 470.181 65.4595C470.122 65.4485 470.059 65.4595 469.997 65.4595C464.393 65.4192 464.249 65.2357 464.98 59.6068C465.087 58.7921 465.368 58.4289 466.233 58.4325C470.17 58.4582 474.108 58.4619 478.046 58.4179C479.21 58.4069 479.527 58.9353 479.509 59.9847C479.468 62.124 479.498 64.2633 479.501 66.4026L479.494 66.4063Z"
          fill="white"
        />
        <path
          d="M188.598 78.409C184.708 78.398 184.627 78.42 183.696 74.6479C183.289 73.0076 182.632 72.3655 180.84 72.4315C176.725 72.5783 172.595 72.5306 168.476 72.4315C167.102 72.3985 166.555 72.9012 166.201 74.1561C165.004 78.398 164.96 78.3944 160.364 78.387C156.175 78.3834 156.452 78.8494 157.586 74.8423C160.442 64.7624 164.08 54.9906 169.392 45.883C170.364 44.2171 171.446 43.23 173.412 43.3548C173.655 43.3695 173.903 43.3291 174.147 43.3034C177.394 42.9328 179.529 43.9859 181.18 47.1123C186.123 56.4694 189.806 66.2668 192.657 76.4129C193.067 77.866 192.75 78.5008 191.183 78.4164C190.326 78.3687 189.462 78.409 188.601 78.409H188.598ZM174.771 65.8302C176.367 65.8302 177.959 65.8412 179.555 65.8265C180.401 65.8192 180.992 65.6614 180.589 64.5348C179.134 60.4544 177.519 56.4437 175.584 52.5651C175.089 51.5743 174.612 51.5964 174.132 52.6091C172.292 56.4657 170.648 60.3994 169.241 64.4284C168.864 65.5073 169.282 65.8742 170.353 65.8485C171.823 65.8118 173.297 65.8375 174.771 65.8338V65.8302Z"
          fill="white"
        />
        <path
          d="M251.526 61.0044C251.526 55.8745 251.563 50.7447 251.503 45.6148C251.485 44.125 251.976 43.5562 253.524 43.5819C258.382 43.6626 263.239 43.6039 268.093 43.6406C270.446 43.6589 272.751 44.0919 274.927 44.9799C279.67 46.9211 282.001 50.7153 281.898 56.1644C281.794 61.5879 279.005 65.4812 274.033 67.4407C270.247 68.9341 266.324 68.7543 262.393 68.736C259.689 68.7213 259.693 68.7286 259.7 71.4183C259.704 73.2494 259.675 75.0841 259.723 76.9152C259.748 77.9243 259.331 78.3793 258.323 78.3756C256.601 78.3683 254.88 78.3573 253.158 78.4086C251.828 78.449 251.515 77.7812 251.526 76.5849C251.57 71.3927 251.544 66.2041 251.544 61.0118H251.526V61.0044ZM259.7 56.2011H259.693C259.693 57.6652 259.686 59.1257 259.693 60.5898C259.697 61.1365 259.663 61.8631 260.417 61.8374C263.649 61.731 266.937 62.3548 270.106 61.3163C272.714 60.4614 274 58.2267 273.73 55.2581C273.501 52.7518 271.95 51.1997 269.213 50.7336C266.468 50.2676 263.701 50.5869 260.945 50.4584C259.929 50.4107 259.686 50.9612 259.693 51.8161C259.704 53.2802 259.693 54.7407 259.693 56.2048L259.7 56.2011Z"
          fill="white"
        />
        <path
          d="M606.572 60.9388C606.572 55.8015 606.565 50.6643 606.583 45.5271C606.583 44.7198 606.343 43.6153 607.691 43.6447C614.204 43.7988 620.753 43.0062 627.222 44.1657C631.651 44.9583 634.997 47.3361 636.313 51.7762C637.687 56.3997 637.107 60.7847 633.634 64.4137C630.856 67.3163 627.199 68.3951 623.31 68.674C620.982 68.8391 618.633 68.7804 616.295 68.718C615.065 68.685 614.658 69.1657 614.684 70.3289C614.732 72.4682 614.666 74.6111 614.71 76.7504C614.732 77.91 614.285 78.4274 613.088 78.387C611.614 78.3393 610.129 78.321 608.659 78.42C607.082 78.5265 606.494 78.0054 606.528 76.3468C606.627 71.2132 606.565 66.0723 606.565 60.9351H606.576L606.572 60.9388ZM614.691 56.0474H614.706C614.706 57.5739 614.714 59.1004 614.706 60.6305C614.703 61.3204 614.858 61.8818 615.693 61.8598C618.515 61.7938 621.367 62.1644 624.156 61.5993C627.288 60.9608 628.91 58.7848 628.747 55.6841C628.596 52.7302 626.952 50.9615 623.69 50.668C621.126 50.4331 618.53 50.5432 615.951 50.4772C614.972 50.4515 614.666 50.9395 614.688 51.8349C614.721 53.2403 614.695 54.6457 614.695 56.0511L614.691 56.0474Z"
          fill="white"
        />
        <path
          d="M598.585 55.5297C598.585 58.1607 598.608 60.788 598.582 63.419C598.478 73.943 592.531 79.5609 581.899 79.15C576.787 78.9518 572.147 77.6565 569.078 73.1431C567.142 70.2992 566.562 67.0444 566.51 63.7126C566.422 57.5993 566.495 51.4823 566.447 45.369C566.436 44.037 566.965 43.5343 568.28 43.4572C573.026 43.171 574.611 44.5287 574.674 49.2403C574.733 53.6436 574.7 58.0469 574.689 62.4466C574.689 63.797 574.704 65.1473 575.051 66.4537C575.849 69.4479 577.725 71.3891 580.858 71.8844C584.001 72.3798 586.946 71.9468 588.962 69.1801C590.473 67.1105 590.673 64.6446 590.673 62.1677C590.669 56.7259 590.732 51.2805 590.617 45.8424C590.58 44.0296 591.223 43.4352 592.948 43.582C594.108 43.6811 595.301 43.7251 596.454 43.5893C598.275 43.3691 598.648 44.2241 598.611 45.8093C598.53 49.0495 598.585 52.2896 598.589 55.5334L598.585 55.5297Z"
          fill="white"
        />
        <path
          d="M415.891 42.9143C419.23 42.962 422.492 43.5087 425.724 44.3307C427.058 44.6683 427.523 45.2223 427.224 46.6424C426.511 50.0257 424.959 51.1852 421.498 50.6531C419.433 50.3376 417.379 49.9449 415.307 49.6807C413.829 49.4936 412.352 49.6367 410.985 50.3119C409.005 51.2843 408.684 53.3502 410.353 54.7739C411.986 56.172 414.084 56.5903 416.075 57.1848C418.787 57.9957 421.469 58.836 423.969 60.2084C427.231 61.9991 428.646 64.8246 428.613 68.3692C428.576 72.1194 427.058 75.2495 423.563 76.908C416.382 80.3133 409.138 79.6822 401.935 76.8053C401 76.431 400.819 75.8219 400.97 74.9192C401.244 73.2936 401.547 71.6644 401.665 70.0242C401.783 68.4169 402.393 68.3252 403.645 69.0518C407.538 71.3048 411.735 72.2258 416.227 71.6497C416.892 71.5653 417.549 71.3708 418.181 71.1507C419.407 70.7287 420.39 70.0792 420.408 68.5857C420.427 67.1326 419.562 66.2813 418.355 65.841C415.817 64.92 413.231 64.1237 410.664 63.2797C409.201 62.799 407.793 62.2119 406.471 61.423C402.452 59.0305 400.597 55.5482 401.366 51.332C402.145 47.0424 405.174 44.8114 409.134 43.6298C411.332 42.9766 413.608 42.8335 415.891 42.9179V42.9143Z"
          fill="white"
        />
        <path
          d="M73.8172 61.9551C73.7433 68.7179 68.4978 73.8478 61.7119 73.7964C55.2067 73.7487 49.7728 68.3693 49.8098 62.0212C49.8504 55.2804 55.2067 49.8423 61.7377 49.9083C68.7453 49.9817 73.8911 55.1153 73.8172 61.9514V61.9551Z"
          fill="#179FEC"
        />
        <path
          d="M381.407 43.6076C386.088 43.6076 390.768 43.6773 395.441 43.5709C397.236 43.5305 397.68 44.2791 397.62 45.857C397.517 48.6751 396.073 50.2933 393.202 50.4364C391.052 50.5428 388.891 50.5539 386.742 50.4511C385.286 50.3814 384.843 50.8584 384.858 52.3152C384.924 60.2632 384.843 68.2149 384.935 76.1629C384.957 77.95 384.403 78.5848 382.63 78.4123C381.411 78.2912 380.166 78.3426 378.94 78.4307C377.266 78.5518 376.716 77.8876 376.734 76.207C376.808 68.4424 376.764 60.6742 376.76 52.9096C376.76 51.2902 375.965 50.4792 374.374 50.4768C371.851 50.4768 369.324 50.4364 366.801 50.5245C365.567 50.5649 365.169 50.0842 365.205 48.9283C365.401 42.5508 364.171 43.7213 370.325 43.6223C374.019 43.5635 377.713 43.6112 381.407 43.6076Z"
          fill="white"
        />
        <path
          d="M61.7453 0C62.6688 2.05122 62.8979 3.79054 63.2562 5.48216C64.6193 11.9367 65.5797 18.45 66.1117 25.0257C66.2336 26.5375 65.9344 27.9282 65.4541 29.3189C64.4383 32.2618 63.4261 35.2047 62.4103 38.1476C62.2958 38.4815 62.2477 38.9365 61.786 38.9292C61.3131 38.9219 61.3021 38.4448 61.1912 38.1183C60.0609 34.8268 58.9268 31.5389 57.8186 28.2438C57.0687 26.0127 57.5563 23.745 57.7854 21.5213C58.4835 14.7182 59.6619 7.98839 61.254 1.33201C61.3205 1.04579 61.4572 0.777924 61.7527 0.00366945L61.7453 0Z"
          fill="#179FEC"
        />
        <path
          d="M61.4977 123C59.9979 117.059 59.152 111.078 58.0733 105.133C56.9947 99.1851 57.7557 93.6186 60.1531 88.1658C60.685 86.9585 60.8808 85.6082 61.5937 84.5C62.3104 84.5367 62.2882 85.0651 62.4138 85.4284C63.4999 88.5437 64.4935 91.6958 65.6645 94.7781C66.4735 96.9064 66.034 99.0017 65.8529 101.115C65.2471 108.131 63.9764 115.041 62.3695 121.892C62.2771 122.281 62.3289 122.776 61.5014 122.996L61.4977 123Z"
          fill="#179FEC"
        />
        <path
          d="M123.535 61.6431C122.689 62.4944 121.71 62.443 120.857 62.6302C114.079 64.1346 107.245 65.2832 100.322 65.9107C97.9616 66.1235 95.7045 65.9584 93.4623 65.0777C90.8949 64.0686 88.2426 63.2797 85.6273 62.388C85.2357 62.2522 84.7148 62.1715 84.7776 61.6064C84.8294 61.1551 85.3096 61.1807 85.6457 61.067C88.963 59.9551 92.2839 58.8616 95.59 57.7241C97.4777 57.0746 99.3764 57.4893 101.253 57.6507C108.549 58.2709 115.726 59.6102 122.848 61.2578C123.011 61.2945 123.155 61.4229 123.535 61.6394V61.6431Z"
          fill="#179FEC"
        />
        <path
          d="M-0.000212233 61.5182C4.13709 60.3403 8.18204 59.6725 12.2196 58.968C13.3093 58.7771 14.4286 58.7221 15.4962 58.4542C23.3718 56.4691 30.8301 57.8634 38.0703 61.1293C38.3954 61.276 38.8904 61.2247 38.931 61.6723C38.9754 62.1384 38.5062 62.1897 38.1627 62.3072C34.5499 63.5144 30.952 64.7547 27.317 65.8959C26.5856 66.1271 25.7286 66.028 24.9344 65.9803C16.9295 65.5032 9.06491 64.1125 1.27053 62.3072C0.87527 62.2154 0.40613 62.2411 -0.00390625 61.5219L-0.000212233 61.5182Z"
          fill="#179FEC"
        />
        <path
          d="M45.7727 78.2509C45.673 78.4417 45.5585 78.6545 45.4477 78.871C43.8149 82.1479 42.1969 85.4357 40.5309 88.6978C40.2317 89.2849 39.8291 89.8831 39.3267 90.294C34.3915 94.3378 29.2531 98.0916 23.4757 100.891C23.0213 101.112 22.5115 101.754 22.0202 101.181C21.5917 100.686 22.1569 100.165 22.3933 99.6988C25.2746 93.9965 28.9687 88.8336 33.1207 83.9936C34.6316 82.2322 36.9921 81.7002 38.9721 80.6287C40.8006 79.6343 42.7326 78.8307 44.6239 77.95C45.0561 77.7482 45.54 77.4143 45.7727 78.2472V78.2509Z"
          fill="#179FEC"
        />
        <path
          d="M101.777 22.5637C101.6 22.8903 101.445 23.1508 101.312 23.426C98.5635 29.0806 94.8842 34.1188 90.9057 38.9588C90.3221 39.667 89.6276 40.2724 88.778 40.6871C85.5753 42.2466 82.3837 43.8281 79.1809 45.3877C78.8226 45.5638 78.4237 45.9711 78.0173 45.5785C77.6886 45.2629 77.9952 44.8923 78.1503 44.5767C79.8569 41.1238 81.5636 37.6671 83.2961 34.2252C83.4808 33.8582 83.8022 33.528 84.1272 33.2601C89.055 29.1834 94.2562 25.4919 99.9856 22.593C100.447 22.3619 101.142 21.5436 101.774 22.5637H101.777Z"
          fill="#179FEC"
        />
        <path
          d="M78.3128 77.6748C78.6527 77.8289 78.9888 77.9757 79.3213 78.1335C82.4686 79.6636 85.6196 81.1865 88.7595 82.7276C90.8466 83.7514 91.9364 85.7439 93.2699 87.4722C96.3027 91.4022 99.1471 95.4606 101.286 99.9594C101.467 100.337 101.881 100.748 101.578 101.126C101.175 101.625 100.654 101.211 100.252 101.005C94.4705 98.0806 89.1733 94.4369 84.2528 90.25C83.7504 89.8244 83.3072 89.2666 83.0079 88.6832C81.3678 85.4687 79.7683 82.2359 78.1946 78.9885C78.0099 78.6105 77.3746 78.0417 78.3165 77.6748H78.3128Z"
          fill="#179FEC"
        />
        <path
          d="M45.7428 45.1894C45.5507 46.0298 45.0557 45.7142 44.6198 45.516C38.388 42.7456 32.1673 40.0706 28.4991 33.7114C26.4932 30.2291 23.9739 27.0367 22.223 23.3856C22.042 23.0113 21.6319 22.6297 21.9607 22.226C22.3301 21.771 22.7549 22.2003 23.1058 22.3728C29.1197 25.2643 34.4797 29.1246 39.5664 33.3812C40.3384 34.027 40.6857 34.9774 41.1253 35.847C42.5511 38.6872 43.9586 41.531 45.3734 44.3785C45.51 44.65 45.6283 44.9326 45.7465 45.1894H45.7428Z"
          fill="#179FEC"
        />
        <path
          d="M70.5367 95.5967C69.3398 95.582 69.6723 94.3601 69.2659 93.7216C68.8744 93.1088 69.144 92.562 69.9235 92.3492C72.6607 91.597 75.2798 90.5548 77.7105 89.0834C78.3385 88.7054 78.8852 88.6834 79.1696 89.4907C79.443 90.276 80.5622 91.1456 79.4356 91.8832C76.7463 93.6445 73.7283 94.7197 70.5367 95.5967Z"
          fill="#179FEC"
        />
        <path
          d="M45.2437 88.7971C45.5466 88.9512 45.8236 89.0797 46.0896 89.2265C48.4501 90.5328 50.8918 91.652 53.5294 92.2868C54.3457 92.4849 54.7706 92.8225 54.2977 93.6702C53.884 94.4151 54.1906 95.9269 52.6169 95.4975C49.658 94.6903 46.8543 93.4793 44.1946 91.9455C43.6848 91.652 43.4484 91.241 43.7845 90.7529C44.2463 90.0851 44.2759 89.076 45.2437 88.7935V88.7971Z"
          fill="#179FEC"
        />
        <path
          d="M34.6977 45.3106C34.513 45.6188 34.3505 45.8757 34.2064 46.1362C32.9209 48.4407 31.7757 50.8001 31.1477 53.3761C30.9483 54.198 30.6121 54.7925 29.6295 54.2898C28.8611 53.8971 27.3614 54.121 27.8527 52.5725C28.7614 49.7213 29.781 46.9032 31.4026 44.3492C31.6501 43.9603 31.8127 43.3438 32.4813 43.7584C33.2349 44.2245 34.1879 44.4263 34.6977 45.3106Z"
          fill="#179FEC"
        />
        <path
          d="M95.7262 52.6749C95.7705 54.2711 94.4998 53.9849 93.7794 54.3409C93.0111 54.7225 92.6749 54.1684 92.4939 53.5152C91.744 50.8622 90.6728 48.3413 89.2764 45.9708C88.7704 45.1159 89.1213 44.7085 89.849 44.448C90.5546 44.1948 91.3045 43.0243 92.0433 44.1765C93.7647 46.8588 94.9246 49.7981 95.7262 52.6749Z"
          fill="#179FEC"
        />
        <path
          d="M52.8653 28.0054C54.1841 27.976 53.944 29.2347 54.3281 29.9429C54.7123 30.6547 54.2653 31.0584 53.5524 31.2455C50.8668 31.9464 48.3438 33.0252 45.9612 34.4379C45.3923 34.7755 44.72 34.959 44.4799 34.104C44.2582 33.3261 42.921 32.5078 44.0662 31.7409C46.7222 29.9575 49.7143 28.842 52.869 28.0054H52.8653Z"
          fill="#179FEC"
        />
        <path
          d="M78.2906 34.7312C77.9619 34.566 77.6811 34.4486 77.4225 34.2945C75.2024 32.9515 72.7865 32.0561 70.3189 31.3112C69.4989 31.0654 68.8635 30.7792 69.292 29.8251C69.6466 29.0325 69.5691 27.6748 71.1169 28.1151C73.9612 28.9224 76.6911 30.0012 79.2363 31.5204C79.8791 31.9057 80.1376 32.3313 79.7202 32.9992C79.325 33.6303 79.2363 34.5403 78.2943 34.7312H78.2906Z"
          fill="#179FEC"
        />
        <path
          d="M95.693 70.8791C94.8693 73.833 93.7168 76.6438 92.132 79.2565C91.3415 80.5628 90.5473 79.3629 89.7974 79.1097C89.066 78.8602 88.7631 78.4308 89.2692 77.6015C90.7024 75.2531 91.7294 72.7175 92.4903 70.0755C92.6861 69.3966 92.8597 68.6738 93.8128 69.2022C94.5553 69.6168 95.9849 69.3196 95.6967 70.8754L95.693 70.8791Z"
          fill="#179FEC"
        />
        <path
          d="M34.6383 78.299C34.3761 79.2127 33.4119 79.1723 32.795 79.5943C32.2409 79.9759 31.7644 79.8255 31.4172 79.242C29.814 76.5597 28.5912 73.7085 27.8672 70.6812C27.5643 69.4116 28.9015 69.6207 29.5812 69.2978C30.139 69.0336 30.7744 68.7951 31.0034 69.7381C31.709 72.593 32.9169 75.2423 34.3945 77.7779C34.4869 77.932 34.5497 78.1045 34.642 78.299H34.6383Z"
          fill="#179FEC"
        />
      </g>
      <defs>
        <clipPath id="clip0_70_182">
          <rect width="637" height="123" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
