import { AxiosResponse } from "axios"

import { BaseService, requestWrapper } from "@utils"
import {
  IStationData,
  IGetGasByAreaPayload,
  TId,
  IStationGas,
  IReceivedParams,
  IGetStationDataParams,
  IGetStationDataByAreaParams,
} from "@types"

class StationGasService extends BaseService {
  getGasByAreaList = ({
    payload,
  }: IReceivedParams<undefined, IGetGasByAreaPayload>): Promise<AxiosResponse<IStationGas[]>> => {
    return this.api.post("/v1/station_gas/", payload)
  }

  getGasByStationList = ({
    params: { stationId },
  }: IReceivedParams<{ stationId: TId }>): Promise<AxiosResponse<IStationGas[]>> => {
    return this.api.get(`/v1/station_gas/${stationId}`)
  }

  getStationData = ({
    params,
  }: IReceivedParams<IGetStationDataParams, undefined>): Promise<AxiosResponse<IStationData[]>> => {
    return this.api({ method: "GET", url: `/v1/data/station_gas/`, params })
  }

  getStationDataByArea = ({
    payload,
  }: IReceivedParams<undefined, IGetStationDataByAreaParams>): Promise<AxiosResponse<IStationData[]>> => {
    return this.api.post(`/v1/data/station_gas/`, payload)
  }
}

const stationGasService = new StationGasService()

export const stationGasAPI = {
  getGasByAreaList: requestWrapper<IStationGas[], typeof stationGasService.getGasByAreaList>(
    stationGasService.getGasByAreaList
  ),
  getGasByStationList: requestWrapper<IStationGas[], typeof stationGasService.getGasByStationList>(
    stationGasService.getGasByStationList
  ),
  getStationData: requestWrapper<IStationData[], typeof stationGasService.getStationData>(
    stationGasService.getStationData
  ),
  getStationDataByArea: requestWrapper<IStationData[], typeof stationGasService.getStationDataByArea>(
    stationGasService.getStationDataByArea
  ),
}
