import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { HashRouter } from "react-router-dom"

import { theme } from "@constants"
import { RoutesComponent } from "@routes"
import { GoogleMapsContextProvider, LocalizationContextProvider } from "@context"

export const App: React.FC = () => {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationContextProvider>
          <GoogleMapsContextProvider>
            <RoutesComponent />
          </GoogleMapsContextProvider>
        </LocalizationContextProvider>
      </ThemeProvider>
    </HashRouter>
  )
}
