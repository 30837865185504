import React, { JSX, ReactNode } from "react"
import { Stack, styled, Typography } from "@mui/material"

interface ICover {
  children: ReactNode
  title?: string | JSX.Element
  headerChildren?: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

/**
 * Обертка компонентов страниц
 *
 * @param {(string | JSX.Element)} [title] - Заголовок
 * @param {ReactNode} children - Дочерние элементы
 * @param {ReactNode} [headerChildren] - Дочерние элементы для хедера
 * @param {Record<string, any>} otherProps - Остальные пропсы
 *
 * @returns {React.FC<ICover>} - React.FC<ICover>
 */
export const Cover: React.FC<ICover> = ({ title, children, headerChildren, ...otherProps }) => {
  return (
    <SCoverWrapper {...otherProps} className="SCoverWrapper">
      <SCoverHeader direction="row" alignItems="center" justifyContent="space-between">
        {typeof title === "string" ? <Typography variant="h4">{title.toUpperCase()}</Typography> : title}

        {headerChildren}
      </SCoverHeader>

      <SCoverBody>{children}</SCoverBody>
    </SCoverWrapper>
  )
}
const SCoverWrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.secondary.A700,
  width: "100%",
  height: "100%",
}))

const SCoverHeader = styled(Stack)({
  height: "33px",
  padding: "0 16px",
})

const SCoverBody = styled(Stack)({
  height: "100%",
})
