import { AxiosResponse } from "axios"

import { BaseService, noArgsRequestWrapper } from "@utils"
import { IGas } from "@types"

class HandbookService extends BaseService {
  getGasHandbook = (): Promise<AxiosResponse<IGas[]>> => {
    return this.api.get("/v1/gas/")
  }
}

const handbookService = new HandbookService()

export const handbookAPI = {
  getGasHandbook: noArgsRequestWrapper<IGas[], typeof handbookService.getGasHandbook>(handbookService.getGasHandbook),
}
