import { AxiosResponse } from "axios"

import { BaseService, requestWrapper } from "@utils"
import { IBounds, TId, IReceivedParams, IStationInfo } from "@types"

class StationService extends BaseService {
  getStation = ({
    params: { stationId },
  }: IReceivedParams<{ stationId: TId }>): Promise<AxiosResponse<IStationInfo>> => {
    return this.api.get(`/v1/station/${stationId}`)
  }

  getStationList = ({ payload }: IReceivedParams<undefined, IBounds>): Promise<AxiosResponse<IStationInfo[]>> => {
    return this.api.post("/v1/station/", payload)
  }
}

const stationService = new StationService()

export const stationAPI = {
  getStation: requestWrapper<IStationInfo, typeof stationService.getStation>(stationService.getStation),
  getStationList: requestWrapper<IStationInfo[], typeof stationService.getStationList>(stationService.getStationList),
}
